import { useState } from "react";

export default function useOauthState() {
  const getState = () => {
    const state = sessionStorage.getItem("state");
    return state;
  };

  const [state, setState] = useState(getState());

  const saveState = (state) => {
    if (state) {
      sessionStorage.setItem("state", state);
      setState(state);
    } else {
      sessionStorage.removeItem("state");
      setState();
    }
  };

  return {
    setState: saveState,
    state,
  };
}
