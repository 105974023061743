import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

export default function Callback({ backend_url, state, setToken }) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const receivedState = searchParams.get("state");
  const receivedCode = searchParams.get("code");
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const getToken = async () => {
      try {
        const response = await fetch(
          `${backend_url}/token?code=${receivedCode}&redirect_uri=${window.location.origin}/callback`,
        );
        if (!response.ok) {
          throw new Error(`HTTP error: Status ${response.status}`);
        }
        const token = await response.json();
        setToken(token);
        setLoggedIn(true);
      } catch (err) {
        setLoggedIn(false);
      } finally {
        // setLoading(false);
      }
    };

    if (state === receivedState) {
      getToken();
    } else {
      navigate("/");
    }
  }, [backend_url, state, setToken, navigate, receivedCode, receivedState]);

  useEffect(() => {
    if (loggedIn) {
      navigate("/");
    }
  }, [loggedIn, navigate]);

  return (
    <div
      style={{
        top: "50%",
        left: "50%",
        transform: "translate3d(-50%,-50%, 0)",
        position: "absolute",
      }}
    >
      <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
    </div>
  );
}
