import maplibregl from "maplibre-gl";
import React, { useRef, useEffect, useState } from "react";
import "maplibre-gl/dist/maplibre-gl.css";

import "./MyMap.css";

export default function MyMap({
  geojsonData,
  setSelectedRoadSegmentGeomMd5,
  setSelectedAlertGeomMd5,
  hoveredFeatureId,
  selectedFeatureId,
  backend_url,
  backend_token,
  from,
  to,
  areAlertsVisible,
  jamsOrIrregularities,
  colorBy,
  maxAngleDegrees,
  minOverlap,
  minCongestionCoefficient,
  congestionRateBinWidthMinutes,
}) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng] = useState(6.16);
  const [lat] = useState(46.22);
  const [zoom] = useState(10.6);

  const selectedAlertId = useRef(null);
  const selectedRoadSegmentId = useRef(null);
  const hoveredAlertId = useRef(null);
  const hoveredRoadSegmentId = useRef(null);
  const prevHoveredFeatureId = useRef(null);
  const prevSelectedFeatureId = useRef(null);
  const roadNetworkTilesPath = useRef(null);

  roadNetworkTilesPath.current =
    jamsOrIrregularities === "jams" ? "jams" : "irregularities";

  // Initialize the map
  useEffect(() => {
    if (map.current) {
      return; // stops map from initializing more than once
    }

    (async () => {
      const customAttribution =
        "<a href='https://ge.ch/sitg/fiche/7206'>Plan SITG</a>";

      const roadNetworkStyle = {
        paint: {
          "line-color": [
            // see https://maplibre.org/maplibre-style-spec/expressions/#data-expressions
            "interpolate-hcl",
            ["linear"],
            ["get", "normalized_cnt"],
            // see https://gradient.page/ui-gradients/rastafari
            // Note: if changing this, make sure to change the legend colors accordingly (in MapSettingsLegend.css)
            0,
            "#1E9600",
            0.5,
            "#FFF200",
            1,
            "#FF0000",
          ],
          "line-width": [
            "case",
            [
              "boolean",
              [
                "any",
                ["boolean", ["feature-state", "hovered"], false],
                ["boolean", ["feature-state", "selected"], false],
              ],
              false,
            ],
            8,
            3,
          ],
          "line-opacity": 0.8,
        },
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
      };

      const closedRoadsStyle = {
        paint: {
          "line-color": "black",
          "line-width": 2,
          "line-opacity": 0.8,
          // 'line-dasharray': [2, 2]
        },
        layout: {
          "line-join": "round",
          "line-cap": "square",
        },
      };

      const mapStyle = {
        version: 8,
        name: "Empty",
        metadata: {
          "mapbox:autocomposite": true,
          "mapbox:type": "template",
        },
        sources: {
          PLAN_SITG: {
            type: "raster",
            tiles: [
              "https://raster.sitg.ge.ch/arcgis/rest/services/PLAN_SITG_EPSG2056/MapServer/export?bbox={bbox-epsg-3857}&size=512,512&bboxSR=3857&&imageSR=3857&format=png&transparent=false&dpi=96&f=image&layers=",
            ],
            tileSize: 512,
            attribution: customAttribution,
          },
          geojsonData: {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: [],
            },
            promoteId: "geometry_md5",
          },
          roadNetworkWithCounts: {
            type: "vector",
            tiles: [
              `${backend_url}/tiles/${roadNetworkTilesPath.current}/{z}/{x}/{y}.pbf?from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}&color_by=${encodeURIComponent(colorBy)}&max_angle_degrees=${encodeURIComponent(maxAngleDegrees)}&min_overlap=${encodeURIComponent(minOverlap)}&min_congestion_coefficient=${encodeURIComponent(minCongestionCoefficient)}&bin_width_minutes=${encodeURIComponent(congestionRateBinWidthMinutes)}`,
            ],
            // promoteId: { "road_network": "geometry_md5" }
            promoteId: "geometry_md5",
          },
          alerts: {
            type: "vector",
            tiles: [
              `${backend_url}/tiles/alerts/{z}/{x}/{y}.pbf?from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}`,
            ],
            promoteId: "geometry_md5",
          },
        },
        layers: [
          {
            id: "PLAN_SITG",
            type: "raster",
            source: "PLAN_SITG",
            layout: {
              visibility: "visible",
            },
            paint: {
              "raster-saturation": -0.8,
            },
          },
          {
            id: "road-network-with-counts",
            type: "line",
            source: "roadNetworkWithCounts",
            "source-layer": "road_network",
            ...roadNetworkStyle,
          },
          {
            id: "closed-roads",
            type: "line",
            source: "roadNetworkWithCounts",
            "source-layer": "closed_roads",
            ...closedRoadsStyle,
          },
          {
            id: "geojson-data",
            type: "line",
            source: "geojsonData",
            layout: {
              visibility: "visible",
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "blue",
              "line-width": [
                "case",
                ["boolean", ["feature-state", "hovered"], false],
                8,
                3,
              ],
              "line-opacity": [
                "case",
                [
                  "any",
                  ["boolean", ["feature-state", "hovered"], false],
                  ["boolean", ["feature-state", "selected"], false],
                ],
                1,
                0,
              ],
            },
          },
          {
            id: "selected-alert",
            type: "circle",
            source: "alerts",
            "source-layer": "alerts",
            layout: {
              visibility: "visible",
            },
            paint: {
              "circle-radius": 20,
              "circle-opacity": 0,
              "circle-stroke-width": 5,
              "circle-stroke-color": "orange",
              "circle-stroke-opacity": [
                "case",
                ["boolean", ["feature-state", "selected"], false],
                1,
                0,
              ],
            },
          },
        ],
      };

      map.current = new maplibregl.Map({
        container: mapContainer.current,
        style: mapStyle,
        center: [lng, lat],
        zoom: zoom,
        maxZoom: 20,
        transformRequest: (url, resourceType) => {
          // authenticated access to MVT tiles
          if (
            resourceType === "Tile" &&
            url.startsWith(`${backend_url}/tiles/`)
          ) {
            return {
              url,
              headers: {
                Authorization: `Bearer ${backend_token}`,
              },
            };
          }
        },
      });

      map.current.addControl(new maplibregl.NavigationControl(), "top-right");

      map.current.on("mouseenter", "road-network-with-counts", (e) => {
        map.current.getCanvas().style.cursor = "crosshair";
        if (e.features.length > 0) {
          if (hoveredRoadSegmentId.current) {
            map.current.setFeatureState(
              {
                source: "roadNetworkWithCounts",
                sourceLayer: "road_network",
                id: hoveredRoadSegmentId.current,
              },
              { hovered: false },
            );
          }
          hoveredRoadSegmentId.current = e.features[0].id;
          map.current.setFeatureState(
            {
              source: "roadNetworkWithCounts",
              sourceLayer: "road_network",
              id: hoveredRoadSegmentId.current,
            },
            { hovered: true },
          );
        }
      });

      map.current.on("mouseleave", "road-network-with-counts", () => {
        map.current.getCanvas().style.cursor = "";
        if (hoveredRoadSegmentId.current) {
          map.current.setFeatureState(
            {
              source: "roadNetworkWithCounts",
              sourceLayer: "road_network",
              id: hoveredRoadSegmentId.current,
            },
            { hovered: false },
          );
        }
        hoveredRoadSegmentId.current = null;
      });

      map.current.on("click", "road-network-with-counts", (e) => {
        setSelectedRoadSegmentGeomMd5(e.features[0].properties.geometry_md5);
        setSelectedAlertGeomMd5(null);
        map.current.setFeatureState(
          {
            source: "alerts",
            sourceLayer: "alerts",
            id: selectedAlertId.current,
          },
          { selected: false },
        );

        map.current.setFeatureState(
          {
            source: "roadNetworkWithCounts",
            sourceLayer: "road_network",
            id: selectedRoadSegmentId.current,
          },
          { selected: false },
        );

        selectedRoadSegmentId.current = e.features[0].id;
        map.current.setFeatureState(
          {
            source: "roadNetworkWithCounts",
            sourceLayer: "road_network",
            id: selectedRoadSegmentId.current,
          },
          { selected: true },
        );
      });

      map.current.on("mousemove", "alerts", (e) => {
        map.current.getCanvas().style.cursor = "crosshair";
        if (e.features.length > 0) {
          if (hoveredAlertId.current) {
            map.current.setFeatureState(
              {
                source: "alerts",
                sourceLayer: "alerts",
                id: hoveredAlertId.current,
              },
              { hovered: false },
            );
          }
          hoveredAlertId.current = e.features[0].id;
          map.current.setFeatureState(
            {
              source: "alerts",
              sourceLayer: "alerts",
              id: hoveredAlertId.current,
            },
            { hovered: true },
          );
        }
      });

      map.current.on("mouseleave", "alerts", () => {
        map.current.getCanvas().style.cursor = "";
        if (hoveredAlertId.current) {
          map.current.setFeatureState(
            {
              source: "alerts",
              sourceLayer: "alerts",
              id: hoveredAlertId.current,
            },
            { hovered: false },
          );
        }
        hoveredAlertId.current = null;
      });

      map.current.on("click", "alerts", (e) => {
        setSelectedAlertGeomMd5(e.features[0].properties.geometry_md5);
        setSelectedRoadSegmentGeomMd5(null);

        map.current.setFeatureState(
          {
            source: "roadNetworkWithCounts",
            sourceLayer: "road_network",
            id: selectedRoadSegmentId.current,
          },
          { selected: false },
        );

        map.current.setFeatureState(
          {
            source: "alerts",
            sourceLayer: "alerts",
            id: selectedAlertId.current,
          },
          { selected: false },
        );

        selectedAlertId.current = e.features[0].id;
        map.current.setFeatureState(
          {
            source: "alerts",
            sourceLayer: "alerts",
            id: selectedAlertId.current,
          },
          { selected: true },
        );
      });

      map.current.on("load", async () => {
        // must credit the author:
        // <a href="https://www.flaticon.com/free-icons/next" title="next icons">Next icons created by Roundicons - Flaticon</a>
        // <a href="https://www.flaticon.com/free-icons/risk" title="risk icons">Risk icons created by Freepik - Flaticon</a>
        // <a href="https://www.flaticon.com/free-icons/close" title="close icons">Close icons created by ariefstudio - Flaticon</a>
        // const image = await map.current.loadImage('https://upload.wikimedia.org/wikipedia/commons/7/7c/201408_cat.png');
        const arrowImg = await map.current.loadImage("small-right-arrow.png");
        map.current.addImage("arrow", arrowImg.data, { sdf: true });

        const crossImg = await map.current.loadImage("cross_64px.png");
        map.current.addImage("cross", crossImg.data, { sdf: true });

        const accidentImg = await map.current.loadImage(
          "car-accident_64px.png",
        );
        map.current.addImage("accident", accidentImg.data, { sdf: false });

        const jamImg = await map.current.loadImage("fleet_64px.png");
        map.current.addImage("jam", jamImg.data, { sdf: false });

        const weatherHazard = await map.current.loadImage("hazardous_64px.png");
        map.current.addImage("weather-hazard", weatherHazard.data, {
          sdf: false,
        });

        const roadClosed = await map.current.loadImage("close_64px.png");
        map.current.addImage("road-closed", roadClosed.data, { sdf: false });

        const questionMark = await map.current.loadImage(
          "question-mark_64px.png",
        );
        map.current.addImage("question-mark", questionMark.data, {
          sdf: false,
        });

        // now that icon images are loaded, let's add the concerned sources and layers
        map.current.addLayer({
          id: "hovered-geojson-data-feature-direction",
          type: "symbol",
          source: "geojsonData",
          layout: {
            "symbol-placement": "line",
            "symbol-spacing": 50,
            "symbol-avoid-edges": false,
            "icon-allow-overlap": true,
            // 'icon-ignore-placement': true,
            "icon-rotate": 0,
            "icon-image": "arrow",
            "icon-size": 1,
            visibility: "visible",
          },
          paint: {
            "icon-color": "blue",
            // "icon-halo-color": "rgb(0, 255, 0)",
            // "icon-halo-width": 10
            "icon-opacity": [
              "case",
              [
                "any",
                ["boolean", ["feature-state", "hovered"], false],
                ["boolean", ["feature-state", "selected"], false],
              ],
              1,
              0,
            ],
          },
          minzoom: 10,
        });

        map.current.addLayer({
          id: "closed-roads-symbols",
          type: "symbol",
          source: "roadNetworkWithCounts",
          "source-layer": "closed_roads",
          layout: {
            "symbol-placement": "line",
            // 'symbol-spacing': 50,
            "symbol-avoid-edges": true,
            "icon-allow-overlap": false,
            // 'icon-ignore-placement': true,
            "icon-rotate": 0,
            "icon-rotation-alignment": "viewport",
            "icon-image": "cross",
            "icon-size": 0.25,
            visibility: "visible",
          },
          paint: {
            "icon-color": "rgb(255, 0, 0)",
            // "icon-halo-color": "rgb(0, 255, 0)",
            // "icon-halo-width": 10
          },
          // minzoom: 10,
        });

        map.current.addLayer({
          id: "alerts",
          type: "symbol",
          source: "alerts",
          "source-layer": "alerts",
          layout: {
            "symbol-placement": "point",
            "icon-allow-overlap": true,
            "icon-rotate": 0,
            "icon-image": [
              "match",
              ["get", "type"],
              "ACCIDENT",
              "accident",
              //
              "JAM",
              "jam",
              //
              "ROAD_CLOSED",
              "road-closed",
              //
              "WEATHERHAZARD",
              "weather-hazard",
              //
              "question-mark",
            ],
            "icon-size": 0.5,
            visibility: "none",
          },
          paint: {
            "icon-opacity": [
              "case",
              ["boolean", ["feature-state", "hovered"], false],
              0.7,
              1,
            ],
          },
        });
      });
    })();
  }, [
    lng,
    lat,
    zoom,
    setSelectedRoadSegmentGeomMd5,
    setSelectedAlertGeomMd5,
    backend_url,
    backend_token,
    from,
    to,
    colorBy,
    maxAngleDegrees,
    minOverlap,
    minCongestionCoefficient,
    congestionRateBinWidthMinutes,
  ]);

  // Update geojsonData source
  useEffect(() => {
    if (map.current) {
      if (map.current.getLayer("geojson-data")) {
        if (geojsonData?.features) {
          map.current.getSource("geojsonData").setData(geojsonData);
        } else {
          map.current.getSource("geojsonData").setData({
            type: "FeatureCollection",
            features: [],
          });
        }
      }
    }
  }, [geojsonData]);

  // Highlight the jam/irregularity feature which is being hovered on the plot
  useEffect(() => {
    if (map.current) {
      if (geojsonData?.features && hoveredFeatureId !== undefined) {
        map.current.setFeatureState(
          { source: "geojsonData", id: hoveredFeatureId },
          { hovered: true },
        );
        map.current.setFeatureState(
          { source: "geojsonData", id: prevHoveredFeatureId.current },
          { hovered: false },
        );
      }
    }

    prevHoveredFeatureId.current = hoveredFeatureId;
  }, [geojsonData, hoveredFeatureId]);

  // Highlight the jam/irregularity feature which has been clicked on the plot
  useEffect(() => {
    if (map.current) {
      if (geojsonData?.features && selectedFeatureId !== undefined) {
        map.current.setFeatureState(
          { source: "geojsonData", id: selectedFeatureId },
          { selected: true },
        );
        map.current.setFeatureState(
          { source: "geojsonData", id: prevSelectedFeatureId.current },
          { selected: false },
        );
      }
    }

    prevSelectedFeatureId.current = selectedFeatureId;
  }, [geojsonData, selectedFeatureId]);

  // Fetch road network with counts
  // TODO: check whether the following is relevant
  useEffect(() => {
    if (map.current) {
      if (map.current.getLayer("road-network-with-counts")) {
        map.current
          .getSource("roadNetworkWithCounts")
          .setTiles([
            `${backend_url}/tiles/road-network/{z}/{x}/{y}.pbf?from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}`,
          ]);
      }
      if (map.current.getLayer("alerts")) {
        map.current
          .getSource("alerts")
          .setTiles([
            `${backend_url}/tiles/alerts/{z}/{x}/{y}.pbf?from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}`,
          ]);
      }
    }
  }, [backend_url, from, to]);

  // Display alerts
  useEffect(() => {
    if (map.current) {
      if (map.current.getLayer("alerts")) {
        map.current.setLayoutProperty(
          "alerts",
          "visibility",
          areAlertsVisible ? "visible" : "none",
        );
      }
    }
  }, [areAlertsVisible]);

  // Switch jams <-> irregularities
  useEffect(() => {
    if (map.current) {
      if (map.current.getLayer("road-network-with-counts")) {
        roadNetworkTilesPath.current =
          jamsOrIrregularities === "jams" ? "jams" : "irregularities";

        map.current
          .getSource("roadNetworkWithCounts")
          .setTiles([
            `${backend_url}/tiles/${roadNetworkTilesPath.current}/{z}/{x}/{y}.pbf?from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}&color_by=${encodeURIComponent(colorBy)}&max_angle_degrees=${encodeURIComponent(maxAngleDegrees)}&min_overlap=${encodeURIComponent(minOverlap)}&min_congestion_coefficient=${encodeURIComponent(minCongestionCoefficient)}&bin_width_minutes=${encodeURIComponent(congestionRateBinWidthMinutes)}`,
          ]);
      }
    }
  }, [
    jamsOrIrregularities,
    backend_url,
    from,
    to,
    colorBy,
    maxAngleDegrees,
    minOverlap,
    minCongestionCoefficient,
    congestionRateBinWidthMinutes,
  ]);

  return (
    <div className="map-wrap">
      <div ref={mapContainer} className="map" />
    </div>
  );
}
