import { LogoutOutlined } from "@ant-design/icons";
import { Button } from "antd";

export default function Logout({ setToken }) {
  const handleClick = () => {
    setToken();
  };

  return (
    <>
      <Button icon={<LogoutOutlined />} onClick={handleClick} type="primary">
        Se déconnecter
      </Button>
    </>
  );
}
