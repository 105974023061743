import { BrowserRouter, Route, Routes } from "react-router-dom";

import useOauthState from "./js/useOauthState.js";
import useToken from "./js/useToken.js";

import "./App.css";
import Callback from "./pages/Callback.js";
import Home from "./pages/Home.js";

function App() {
  const backend_url = process.env.REACT_APP_BACKEND_URL || "__BACKEND_URL__";

  const { token, setToken } = useToken();
  const { state, setState } = useOauthState();

  if (!state) {
    setState(window.crypto.randomUUID());
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Home
              backend_url={backend_url}
              token={token}
              setToken={setToken}
              state={state}
            />
          }
        />
        <Route
          path="/callback"
          element={
            <Callback
              backend_url={backend_url}
              state={state}
              setToken={setToken}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
