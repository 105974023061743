// import sumOfIntervalsWithoutOverlaps from './sumOfIntervalsWithoutOverlaps.js';
import noOfCongestedBins from "./noOfCongestedBins.js";

export default function buildPlotData(
  data,
  yAxisVar,
  congestionRateIntervalMillis,
) {
  const MARKER_SIZE_OFFSET = 4;
  const MARKER_SIZE_SCALING = 2;

  if (!data) {
    return [];
  }

  const groupedData = Object.groupBy(data, (datum) => datum.uuid);
  const groupCount = Object.keys(groupedData).length;

  const plotData = [];

  for (const x of [0, 1, 2, 3, 4, 5]) {
    plotData.push({
      x: [null],
      y: [null],
      legendgroup: "group2",
      legendgrouptitle: {
        text: "Niveau de trafic",
        itemsizing: "constant",
      },
      name: x,
      mode: "markers",
      marker: {
        size: MARKER_SIZE_OFFSET + MARKER_SIZE_SCALING * x,
        color: "rgb(0, 0, 0)",
      },
    });
  }

  for (const [key, value] of Object.entries(groupedData)) {
    plotData.push({
      x: value.reduce(
        (accumulator, currentValue) =>
          accumulator.concat(currentValue._file_received_at),
        [],
      ),
      y: value.reduce(
        (accumulator, currentValue) =>
          accumulator.concat(currentValue[yAxisVar]),
        [],
      ),
      text: value.reduce(
        (accumulator, currentValue) =>
          accumulator.concat(`Niveau de trafic = ${currentValue.level}`),
        [],
      ),
      customdata: value.reduce(
        (accumulator, currentValue) =>
          accumulator.concat(currentValue.geometry_md5),
        [],
      ),
      marker: {
        size: value.reduce(
          (accumulator, currentValue) =>
            accumulator.concat(
              MARKER_SIZE_OFFSET +
                MARKER_SIZE_SCALING * currentValue.level +
                // the following line is due to the fact that `circle-open` markers seem to render bigger than `circle` ones,
                // so we artificially make open circles (close circles) smaller (bigger) by subtracting (adding) 1
                (currentValue.orientation === -1 ? 1 : -1),
            ),
          [],
        ),
        symbol: value.reduce(
          (accumulator, currentValue) =>
            accumulator.concat(
              currentValue.orientation === -1 ? "circle" : "circle-open",
            ),
          [],
        ),
      },
      name: key,
      type: "scatter",
      mode: "lines+markers",
      legendgroup: "group1",
      legendgrouptitle: {
        text: "UUID",
      },
    });
  }

  // let congestionTime = sumOfIntervalsWithoutOverlaps(congestedTimeSpans);
  const noCongestedBins = noOfCongestedBins(
    data.map((datum) => datum._file_received_at),
    congestionRateIntervalMillis,
  );

  return [groupCount, plotData, noCongestedBins];
}
