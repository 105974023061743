import { InfoCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Collapse,
  InputNumber,
  List,
  Modal,
  Row,
  Select,
  Slider,
} from "antd";
import React, { useState, useEffect } from "react";

import "./MyMapSettingsLegend.css";

const DisplayModes = {
  event_count: "Comptage d'événements",
  congestion_rate: "Taux de congestion",
};

export default function MyMapSettings({
  colorBy,
  setColorBy,
  maxAngleDegrees,
  setMaxAngleDegrees,
  minOverlapPercentage,
  setMinOverlapPercentage,
  minCongestionCoefficient,
  setMinCongestionCoefficient,
  // congestionRateBinWidthMinutes, setCongestionRateBinWidthMinutes,
  areAlertsVisible,
}) {
  const ColorSetting = ({ colorBy, setColorBy }) => {
    const [selectedValue, setSelectedValue] = useState(colorBy);

    useEffect(() => {
      const timeoutId = setTimeout(() => {
        setColorBy(selectedValue); // this goes upstream to the parent component
      }, 500);
      return () => clearTimeout(timeoutId);
    }, [selectedValue, setColorBy]);

    return (
      <Select
        defaultValue={selectedValue}
        onChange={setSelectedValue}
        options={Object.entries(DisplayModes).map(([idx, lbl]) => ({
          value: idx,
          label: lbl,
        }))}
      />
    );
  };

  const MaxAngleSetting = ({ maxAngleDegrees, setMaxAngleDegrees }) => {
    const minValue = 0;
    const maxValue = 90;

    const [inputValue, setInputValue] = useState(maxAngleDegrees);

    useEffect(() => {
      const timeoutId = setTimeout(() => {
        setMaxAngleDegrees(inputValue); // this goes upstream to the parent component
      }, 500);
      return () => clearTimeout(timeoutId);
    }, [inputValue, setMaxAngleDegrees]);

    const formatter = (value) => `${value}°`;

    return (
      <Row gutter={[8, 8]}>
        <Col span={12}>
          <Slider
            min={minValue}
            max={maxValue}
            onChange={setInputValue}
            value={typeof inputValue === "number" ? inputValue : null}
            tooltip={{
              formatter,
            }}
          />
        </Col>
        <Col span={12}>
          <InputNumber
            min={minValue}
            max={maxValue}
            value={inputValue}
            onChange={setInputValue}
            addonAfter="°"
          />
        </Col>
      </Row>
    );
  };

  const MinOverlapSetting = ({
    minOverlapPercentage,
    setMinOverlapPercentage,
  }) => {
    const minValue = 0;
    const maxValue = 100;

    const [inputValue, setInputValue] = useState(
      maxValue - minOverlapPercentage,
    );

    useEffect(() => {
      const timeoutId = setTimeout(() => {
        setMinOverlapPercentage(maxValue - inputValue); // this goes upstream to the parent component
      }, 500);
      return () => clearTimeout(timeoutId);
    }, [inputValue, setMinOverlapPercentage]);

    const formatter = (value) => `${maxValue - value}%`;

    return (
      <Row gutter={[8, 8]}>
        <Col span={12}>
          <Slider
            min={minValue}
            max={maxValue}
            onChange={setInputValue}
            value={Number.isNaN(inputValue) ? null : inputValue}
            tooltip={{
              formatter,
            }}
            reverse
          />
        </Col>
        <Col span={12}>
          <InputNumber
            min={minValue}
            max={maxValue}
            value={maxValue - inputValue}
            onChange={(v) => {
              if (v != null) {
                setInputValue(maxValue - v);
              } else {
                setInputValue(Number.NaN);
              }
            }}
            addonAfter="%"
          />
        </Col>
      </Row>
    );
  };

  const MinCongestionCoefficientSetting = ({
    minCongestionCoefficient,
    setMinCongestionCoefficient,
  }) => {
    const minValue = 0.0;
    const maxValue = 1.0;
    const step = 0.01;
    const precision = 2;

    const [inputValue, setInputValue] = useState(
      maxValue - minCongestionCoefficient,
    );

    useEffect(() => {
      const timeoutId = setTimeout(() => {
        setMinCongestionCoefficient(
          +(maxValue - inputValue).toFixed(precision),
        ); // this goes upstream to the parent component
      }, 500);
      return () => clearTimeout(timeoutId);
    }, [inputValue, setMinCongestionCoefficient]);

    const formatter = (value) => `${(maxValue - value).toFixed(precision)}`;

    return (
      <Row gutter={[8, 0]} align={"middle"} justify="start">
        <Col span={10}>
          <Slider
            min={minValue}
            max={maxValue}
            onChange={setInputValue}
            value={Number.isNaN(inputValue) ? null : inputValue}
            tooltip={{
              formatter,
            }}
            reverse
            step={step}
          />
        </Col>
        <Col span={9}>
          <InputNumber
            min={minValue}
            max={maxValue}
            value={maxValue - inputValue}
            onChange={(v) => {
              if (v != null) {
                setInputValue(maxValue - v);
              } else {
                setInputValue(Number.NaN);
              }
            }}
            step={step}
            precision={precision}
            style={{
              width: "100%",
            }}
          />
        </Col>
        <Col span={3}>
          k=
          {Number.isNaN(inputValue)
            ? ""
            : (1 / (1 - maxValue + inputValue)).toFixed(1)}
        </Col>
      </Row>
    );
  };

  // const CongestionRateBinWidthMinutesSetting = ({ congestionRateBinWidthMinutes, setCongestionRateBinWidthMinutes }) => {

  //     const minValue = 1;
  //     const maxValue = 60*3; // 3 hours

  //     const [inputValue, setInputValue] = useState(congestionRateBinWidthMinutes);

  //     useEffect(() => {
  //         const timeoutId = setTimeout(() => {
  //             setCongestionRateBinWidthMinutes(inputValue); // this goes upstream to the parent component
  //         }, 500);
  //         return () => clearTimeout(timeoutId);
  //     }, [inputValue, setCongestionRateBinWidthMinutes]);

  //     const formatter = (value) => `${value} min`;

  //     return (
  //         <Row gutter={[8, 8]}>
  //             <Col span={12}>
  //                 <Slider
  //                     min={minValue}
  //                     max={maxValue}
  //                     onChange={setInputValue}
  //                     value={typeof inputValue === 'number' ? inputValue : null}
  //                     tooltip={{
  //                         formatter,
  //                     }}
  //                 />
  //             </Col>
  //             <Col span={12}>
  //                 <InputNumber
  //                     min={minValue}
  //                     max={maxValue}
  //                     value={inputValue}
  //                     onChange={setInputValue}
  //                     addonAfter="min"
  //                 />
  //             </Col>
  //         </Row>
  //     );
  // };

  const Legend = ({ colorBy, areAlertsVisible }) => {
    return (
      <div className="legend">
        <h3>Graphe routier</h3>
        <section>
          <div className="gradient-container">
            <svg width="5" height="50" xmlns="http://www.w3.org/2000/svg">
              <title>Legend entry for congested road segments</title>
              <defs>
                <linearGradient
                  id="verticalGradient"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop offset="0%" stopColor="#FF0000" />
                  <stop offset="50%" stopColor="#FFF200" />
                  <stop offset="100%" stopColor="#1E9600" />
                </linearGradient>
              </defs>
              <rect
                x="0"
                y="0"
                width="5"
                height="50"
                fill="url(#verticalGradient)"
              />
            </svg>
            {colorBy === "congestion_rate" && (
              <div className="ticks">
                <div>100%</div>
                <div>0%</div>
              </div>
            )}
          </div>
          <div>{DisplayModes[colorBy]}</div>
          <div>
            <svg width="30" height="20">
              <title>Legend entry for closed roads</title>
              <line
                x1="0"
                y1="10"
                x2="30"
                y2="10"
                stroke="black"
                strokeWidth="2"
              />
              <line
                x1="10"
                y1="5"
                x2="20"
                y2="15"
                stroke="red"
                strokeWidth="3"
              />
              <line
                x1="10"
                y1="15"
                x2="20"
                y2="5"
                stroke="red"
                strokeWidth="3"
              />
            </svg>
          </div>
          <div>Blocage</div>
        </section>

        {areAlertsVisible && (
          <>
            <h3>Alertes</h3>
            <section>
              <img src="fleet_64px.png" alt="bouchon" />
              <div>Congestion inhabituelle</div>
              <img src="car-accident_64px.png" alt="accident" />
              <div>Accident</div>
              <img src="hazardous_64px.png" alt="incident" />
              <div>Danger / incident</div>
              <img src="close_64px.png" alt="fermé" />
              <div>Route barrée</div>
              <img src="question-mark_64px.png" alt="inconnu" />
              <div>Autre évènement</div>
            </section>
          </>
        )}
      </div>
    );
  };

  const maxAngleDoc = () => {
    Modal.info({
      title: "Angle maximum",
      content: <div>...</div>,
      footer: null,
      closable: true,
      maskClosable: true,
    });
  };

  const minOverlapDoc = () => {
    Modal.info({
      title: "Recoupement minimum",
      content: <div>...</div>,
      footer: null,
      closable: true,
      maskClosable: true,
    });
  };

  const minCongestionCoefficientDoc = () => {
    Modal.info({
      title: "Coefficient de congestion minimum",
      content: <div>...</div>,
      footer: null,
      closable: true,
      maskClosable: true,
    });
  };

  // const congestionRateBinWidthMinutesDoc = () => {
  //     Modal.info({
  //         title: "Intervalle d'agrégation pour calculer le taux de congestion",
  //         content: (
  //             <div>
  //                 ...
  //             </div>
  //         ),
  //         footer: null,
  //         closable: true,
  //         maskClosable: true
  //     });
  // };

  const data = [
    {
      key: "color-by",
      title: "Colorier les tronçons selon le",
      content: <ColorSetting colorBy={colorBy} setColorBy={setColorBy} />,
    },
    {
      key: "angle-max",
      title: (
        <div>
          <span style={{ marginRight: "5px" }}>Angle max</span>
          <Button
            size="small"
            color="black"
            variant="text"
            onClick={maxAngleDoc}
          >
            <InfoCircleOutlined />
          </Button>
        </div>
      ),
      content: (
        <MaxAngleSetting
          maxAngleDegrees={maxAngleDegrees}
          setMaxAngleDegrees={setMaxAngleDegrees}
        />
      ),
    },
    {
      key: "min-overlap",
      title: (
        <div>
          <span style={{ marginRight: "5px" }}>Recoupement min</span>
          <Button
            size="small"
            color="black"
            variant="text"
            onClick={minOverlapDoc}
          >
            <InfoCircleOutlined />
          </Button>
        </div>
      ),
      content: (
        <MinOverlapSetting
          minOverlapPercentage={minOverlapPercentage}
          setMinOverlapPercentage={setMinOverlapPercentage}
        />
      ),
    },
    {
      key: "min-congestion-coeff",
      title: (
        <div>
          <span style={{ marginRight: "5px" }}>Coeff. de congestion min</span>
          <Button
            size="small"
            color="black"
            variant="text"
            onClick={minCongestionCoefficientDoc}
          >
            <InfoCircleOutlined />
          </Button>
        </div>
      ),
      content: (
        <MinCongestionCoefficientSetting
          minCongestionCoefficient={minCongestionCoefficient}
          setMinCongestionCoefficient={setMinCongestionCoefficient}
        />
      ),
    },
    // {
    //     title: <div><span style={{ marginRight: "5px" }}>Plage d'agrégation...</span><Button size="small" color="black" variant="text" onClick={congestionRateBinWidthMinutesDoc} >
    //         <InfoCircleOutlined />
    //     </Button></div>,
    //     content: <CongestionRateBinWidthMinutesSetting congestionRateBinWidthMinutes={congestionRateBinWidthMinutes} setCongestionRateBinWidthMinutes={setCongestionRateBinWidthMinutes} />
    // }
  ];

  const mapSettingItems = (
    <div
      style={{
        maxHeight: "calc(100vh - 430px)",
        overflowY: "auto",
        scrollbarWidth: "thin",
      }}
    >
      <List
        size="small"
        itemLayout="horizontal"
        dataSource={data}
        rowKey="key"
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              key={item.key}
              title={item.title}
              description={item.content}
            />
          </List.Item>
        )}
      />
    </div>
  );

  return (
    <div style={{ width: "270px" }}>
      <Collapse
        size="small"
        accordion={true}
        collapsible="header"
        defaultActiveKey={["2"]}
        bordered={false}
        items={[
          {
            key: "1",
            label: (
              <span style={{ fontSize: "16px" }}>
                Configuration de la carte
              </span>
            ),
            children: mapSettingItems,
          },
          {
            key: "2",
            label: <span style={{ fontSize: "16px" }}>Légende</span>,
            children: (
              <Legend colorBy={colorBy} areAlertsVisible={areAlertsVisible} />
            ),
          },
        ]}
        style={{ background: "white", height: "100%" }}
      />
    </div>
  );
}
