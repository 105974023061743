export default function buildAlertsPlotData(data, yAxisVar) {
  // const MARKER_SIZE_OFFSET = 4;
  // const MARKER_SIZE_SCALING = 2;

  if (!data) {
    return [];
  }

  const groupedData = Object.groupBy(data, (datum) => datum.uuid);
  const groupCount = Object.keys(groupedData).length;

  const plotData = [];

  const markers = new Map();

  markers.set("JAM", "diamond");
  markers.set("ROAD_CLOSED", "triangle-up");
  markers.set("WEATHERHAZARD", "star");
  markers.set("ACCIDENT", "pentagon");
  markers.set("UNKNOWN", "x");

  for (const [label, symbol] of markers) {
    plotData.push({
      x: [null],
      y: [null],
      legendgroup: "group2",
      legendgrouptitle: {
        text: "Type d'alerte",
        itemsizing: "trace",
      },
      name: label,
      mode: "markers",
      marker: {
        size: 14,
        color: "rgb(0, 0, 0)",
        symbol: symbol,
      },
    });
  }

  for (const [key, value] of Object.entries(groupedData)) {
    plotData.push({
      x: value.reduce(
        (accumulator, currentValue) =>
          accumulator.concat(currentValue._file_received_at),
        [],
      ),
      y: value.reduce(
        (accumulator, currentValue) =>
          accumulator.concat(currentValue[yAxisVar]),
        [],
      ),
      text: value.reduce(
        (accumulator, currentValue) =>
          accumulator.concat(
            `Type: ${currentValue.type}<br>Sous-type: ${currentValue.subtype}<br>D'actualité depuis le ${new Date(currentValue._pub_datetime).toLocaleString("fr", { weekday: "short", year: "2-digit", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric" }).replace(/\//g, "-")}`,
          ),
        [],
      ),
      customdata: value.reduce(
        (accumulator, currentValue) =>
          accumulator.concat(currentValue.geometry_md5),
        [],
      ),
      marker: {
        size: 10,
        symbol: value.reduce(
          (accumulator, currentValue) =>
            accumulator.concat(
              markers.get(currentValue.type)
                ? markers.get(currentValue.type)
                : markers.get("UNKNOWN"),
            ),
          [],
        ),
      },
      name: key,
      type: "scatter",
      mode: "lines+markers",
      legendgroup: "group1",
      legendgrouptitle: {
        text: "UUID",
      },
    });
  }

  // it can happen that there are multiple events overlapping esp. since we consider both directions for one segment.
  // thus we need to sum the intervals without conting overlaps multiple time (otherwise we could have more than 100% congestion)
  return [groupCount, plotData];
}
