import { InfoCircleOutlined } from "@ant-design/icons";
import { Badge, Button, DatePicker, Modal, Space, Switch } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

import Login from "../components/Login.js";
import Logout from "../components/Logout.js";
import MyMap from "../components/MyMap.js";
import MyMapSettings from "../components/MyMapSettings.js";
import Plot from "../components/Plot.js";
import PlotSettings from "../components/PlotSettings.js";
import buildAlertsPlotData from "../js/buildAlertsPlotData.js";
import buildJamsPlotData from "../js/buildJamsPlotData.js";

const { RangePicker } = DatePicker;

export default function Home({ backend_url, token, setToken, state }) {
  const [selectedRoadSegmentGeomMd5, setSelectedRoadSegmentGeomMd5] =
    useState(null);
  const [selectedAlertGeomMd5, setSelectedAlertGeomMd5] = useState(null);
  const [geojsonData, setGeojsonData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [from, setFrom] = useState(
    dayjs("2023-03-31", "YYYY-MM-DD")
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .format(),
  );
  const [to, setTo] = useState(
    dayjs("2023-04-02", "YYYY-MM-DD")
      .set("hour", 23)
      .set("minute", 59)
      .set("second", 59)
      .format(),
  );
  const [plotData, setPlotData] = useState([]);
  // const [plotYAxisVarOptions, setPlotYAxisVarOptions] = useState([]);
  // const [plotYAxisVar, setPlotYAxisVar] = useState();
  const [alertsPlotYAxisVar, setAlertsPlotYAxisVar] = useState("reliability");
  const [
    jamsOrIrregularitiesPlotYAxisVar,
    setJamsOrIrregularitiesPlotYAxisVar,
  ] = useState("delay_minutes");
  const [congestionRate, setCongestionRate] = useState(null);
  const [eventsCount, setEventsCount] = useState();
  const [recordsCount, setRecordsCount] = useState();
  const [loadingTime, setLoadingTime] = useState();
  const [hoveredFeatureId, setHoveredFeatureId] = useState("");
  const [selectedFeatureId, setSelectedFeatureId] = useState("");
  const [hasFetchFailed, setHasFetchFailed] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(true);
  const [areAlertsVisible, setAreAlertsVisible] = useState(false);
  const [areJamsVisible, setAreJamsVisible] = useState(true);
  const [areIrregularitiesVisible, setAreIrregularitiesVisible] =
    useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [colorBy, setColorBy] = useState("congestion_rate");
  const [maxAngleDegrees, setMaxAngleDegrees] = useState(30);
  const [minOverlapPercentage, setMinOverlapPercentage] = useState(50);
  const [minCongestionCoefficient, setMinCongestionCoefficient] = useState(0);
  // const [congestionRateBinWidthMinutes, setCongestionRateBinWidthMinutes] = useState(1);

  const CONGESTION_RATE_BIN_WIDTH_MINUTES = 1;

  const handleOnRangePickerChange = (dates, dateString) => {
    setFrom(dates[0].set("hour", 0).set("minute", 0).set("second", 0).format());
    setTo(
      dates[1].set("hour", 23).set("minute", 59).set("second", 59).format(),
    );
  };

  const handleAlertsSwitchChange = () => {
    setAreAlertsVisible(!areAlertsVisible);
  };

  const handleJamsOrIrregularitiesSwitchChange = () => {
    setAreJamsVisible(!areJamsVisible);
    setAreIrregularitiesVisible(!areIrregularitiesVisible);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  const yAxisOptionsValueToLabel = {
    reliability: "fiabilité",
    n_thumbs_up: "# thumbs up",
    delay_minutes: "retard [min]",
    congestion_coefficient: "coeff. de congestion",
  };

  const plotYAxisVarOptions = {
    alerts: [
      {
        value: "reliability",
        label: yAxisOptionsValueToLabel.reliability,
      },
      {
        value: "n_thumbs_up",
        label: yAxisOptionsValueToLabel.n_thumbs_up,
      },
    ],
    jamsOrIrregularities: [
      {
        value: "delay_minutes",
        label: yAxisOptionsValueToLabel.delay_minutes,
      },
      {
        value: "congestion_coefficient",
        label: yAxisOptionsValueToLabel.congestion_coefficient,
      },
    ],
  };

  // check token
  useEffect(() => {
    const checkToken = async () => {
      const response = await fetch(`${backend_url}/checktoken`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setIsTokenValid(response.ok);
    };

    checkToken();
  }, [token, backend_url]);

  // display jam / irregularity features
  useEffect(() => {
    if (selectedRoadSegmentGeomMd5 === null) {
      // setGeojsonData(null);
      return;
    }

    // see https://devtrium.com/posts/async-functions-useeffect
    const fetchData = async () => {
      setHasFetchFailed(false);
      setIsLoading(true);
      const time1 = performance.now();

      const path = areJamsVisible ? "jams" : "irregularities";
      // get the data from the api
      const eventsResponse = await fetch(
        `${backend_url}/${path}/events?geometry_md5=${selectedRoadSegmentGeomMd5}&from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}&max_angle_degrees=${encodeURIComponent(maxAngleDegrees)}&min_overlap=${encodeURIComponent(minOverlapPercentage / 100.0)}&min_congestion_coefficient=${encodeURIComponent(minCongestionCoefficient)}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const geometriesResponse = await fetch(
        `${backend_url}/${path}/geometries?geometry_md5=${selectedRoadSegmentGeomMd5}&from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}&max_angle_degrees=${encodeURIComponent(maxAngleDegrees)}&min_overlap=${encodeURIComponent(minOverlapPercentage / 100.0)}&min_congestion_coefficient=${encodeURIComponent(minCongestionCoefficient)}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const time2 = performance.now();
      setLoadingTime(time2 - time1);

      if (!eventsResponse.ok | !geometriesResponse.ok) {
        setHasFetchFailed(true);
        throw new Error("Could not fetch JSON data");
      }

      const events = await eventsResponse.json();
      const geometries = await geometriesResponse.json();

      return { events, geometries };
    };

    fetchData()
      .then(({ events, geometries }) => {
        setGeojsonData(geometries);
        if (events) {
          setRecordsCount(events.length);
          const [_eventsCount, _plotData, noCongestedBins] = buildJamsPlotData(
            events,
            jamsOrIrregularitiesPlotYAxisVar,
            CONGESTION_RATE_BIN_WIDTH_MINUTES * 60000,
          );
          setPlotData(_plotData);
          setEventsCount(_eventsCount);
          const noBins = Math.ceil(
            (Date.parse(to) - Date.parse(from)) /
              (CONGESTION_RATE_BIN_WIDTH_MINUTES * 60000),
          );
          setCongestionRate(noCongestedBins / noBins);
          setCongestionRate(noCongestedBins / noBins);
        } else {
          setPlotData([]);
          setEventsCount(undefined);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setPlotData([]);
        setEventsCount(undefined);
        setLoadingTime();
        setIsLoading(false);
        if (error.message === "403") {
          setToken(); // logout
        }
      });
  }, [
    selectedRoadSegmentGeomMd5,
    from,
    to,
    backend_url,
    token,
    setToken,
    areJamsVisible,
    maxAngleDegrees,
    minOverlapPercentage,
    minCongestionCoefficient,
    jamsOrIrregularitiesPlotYAxisVar,
  ]);

  // display alert features
  useEffect(() => {
    if (selectedAlertGeomMd5 === null) {
      return;
    }

    // see https://devtrium.com/posts/async-functions-useeffect
    const fetchData = async () => {
      setHasFetchFailed(false);
      setIsLoading(true);
      const time1 = performance.now();
      // get the data from the api
      const eventsResponse = await fetch(
        `${backend_url}/alerts/events?geometry_md5=${selectedAlertGeomMd5}&from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const time2 = performance.now();
      setLoadingTime(time2 - time1);

      if (!eventsResponse.ok) {
        setHasFetchFailed(true);
        throw new Error("Could not fetch JSON data");
      }

      const events = await eventsResponse.json();

      return { events };
    };

    fetchData()
      .then(({ events }) => {
        setGeojsonData([]);
        if (events) {
          setRecordsCount(events.length);
          const [_eventsCount, _plotData] = buildAlertsPlotData(
            events,
            alertsPlotYAxisVar,
          );
          setPlotData(_plotData);
          setEventsCount(_eventsCount);
          setCongestionRate(null);
        } else {
          setPlotData([]);
          setEventsCount(undefined);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.message === "403") {
          setToken(); // logout
        }
      });
  }, [
    selectedAlertGeomMd5,
    from,
    to,
    backend_url,
    token,
    setToken,
    alertsPlotYAxisVar,
  ]);

  if (!token || !isTokenValid) {
    return (
      <div
        style={{
          top: "50%",
          left: "50%",
          transform: "translate3d(-50%,-50%, 0)",
          position: "absolute",
        }}
      >
        <Login backend_url={backend_url} state={state} />
      </div>
    );
  }

  return (
    <div className="App">
      <Modal
        title="À propos de cette application"
        open={isModalOpen}
        onCancel={handleModalCancel}
        footer={null}
      >
        <p>[...]</p>
        <h3>Crédits</h3>
        <ul>
          <li>
            <a href="https://www.flaticon.com/authors/freepik">
              Icônes crées par Freepik - Flaticon
            </a>
          </li>
        </ul>
      </Modal>

      {/* header */}
      <div
        style={{
          position: "absolute",
          top: 0,
          height: "50px",
          width: "100%",
          background: "white",
          display: "flex",
          justifyContent: "space-between",
          padding: 10,
          margin: "auto",
          alignItems: "center",
        }}
      >
        <h2>Waze Data Explorer</h2>

        {/* controls */}
        <div>
          <Space size={"large"}>
            <Space>
              Plage temporelle{" "}
              <RangePicker
                onChange={handleOnRangePickerChange}
                value={[dayjs(from, "YYYY-MM-DD"), dayjs(to, "YYYY-MM-DD")]}
              />
            </Space>
            <Space>
              Alertes{" "}
              <Switch
                value={areAlertsVisible}
                onChange={handleAlertsSwitchChange}
              />
            </Space>
            <Space>
              Bouchons{" "}
              <Switch
                value={areJamsVisible}
                onChange={handleJamsOrIrregularitiesSwitchChange}
              />
            </Space>
            <Space>
              Irrégularités{" "}
              <Switch
                value={areIrregularitiesVisible}
                onChange={handleJamsOrIrregularitiesSwitchChange}
              />
            </Space>
          </Space>
        </div>

        <Space>
          <Button
            type="default"
            onClick={showModal}
            icon={<InfoCircleOutlined />}
          >
            À propos
          </Button>
          <Logout setToken={setToken} />
        </Space>
      </div>

      <div
        style={{
          padding: "10px",
          background: "white",
          height: 300,
          width: "100%",
          bottom: "0px",
          left: "0px",
          position: "absolute",
          zIndex: 1,
        }}
      >
        {/* plot header */}
        <div
          style={{
            width: "100%",
            height: "35px",
            background: "white",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {!isLoading && plotData.length > 0 && (
            <>
              {selectedAlertGeomMd5 && (
                <PlotSettings
                  options={plotYAxisVarOptions.alerts}
                  yAxisVar={alertsPlotYAxisVar}
                  setYAxisVar={setAlertsPlotYAxisVar}
                />
              )}
              {selectedRoadSegmentGeomMd5 && (
                <PlotSettings
                  options={plotYAxisVarOptions.jamsOrIrregularities}
                  yAxisVar={jamsOrIrregularitiesPlotYAxisVar}
                  setYAxisVar={setJamsOrIrregularitiesPlotYAxisVar}
                />
              )}
              <Space size={"large"}>
                <div>
                  <Badge
                    count={recordsCount}
                    overflowCount={10000}
                    color={"green"}
                  />{" "}
                  enregistrements
                </div>
                <div>
                  <Badge
                    count={eventsCount}
                    overflowCount={10000}
                    color={"blue"}
                  />{" "}
                  événements uniques
                </div>
                {congestionRate !== null && (
                  <div>
                    <Badge
                      count={`${Math.round(congestionRate * 100)} %`}
                      overflowCount={10000}
                      color={"red"}
                    />{" "}
                    taux de congestion
                  </div>
                )}
              </Space>
              {!isLoading &&
                !hasFetchFailed &&
                (selectedRoadSegmentGeomMd5 || selectedAlertGeomMd5) && (
                  <div style={{ color: "gray" }}>
                    {(loadingTime / 1000).toFixed(3)} s
                  </div>
                )}
            </>
          )}
        </div>
        {/* plot */}
        <div>
          <div>
            {!(selectedRoadSegmentGeomMd5 || selectedAlertGeomMd5) ? (
              <div style={{ fontSize: 30, marginTop: 80 }}>
                Veuillez séléctionner un tronçon ou une alerte sur la carte...
              </div>
            ) : isLoading ? (
              <div style={{ fontSize: 30, marginTop: 80 }}>
                Chargement des données en cours...
              </div>
            ) : hasFetchFailed ? (
              <div style={{ fontSize: 30, marginTop: 80 }}>
                Le chargement des données a échoué :-(
              </div>
            ) : plotData.length > 0 ? (
              <Plot
                plotData={plotData}
                from={from}
                to={to}
                yaxisTitle={
                  selectedRoadSegmentGeomMd5
                    ? yAxisOptionsValueToLabel[jamsOrIrregularitiesPlotYAxisVar]
                    : yAxisOptionsValueToLabel[alertsPlotYAxisVar]
                }
                setHoveredFeatureId={setHoveredFeatureId}
                setSelectedFeatureId={setSelectedFeatureId}
              />
            ) : (
              <div style={{ fontSize: 30, marginTop: 80 }}>
                Aucune donnée n'est disponible pour cette sélection.
              </div>
            )}
          </div>
        </div>
      </div>

      {/* map */}

      <div>
        <div
          style={{
            padding: "0px",
            top: "60px",
            left: "10px",
            position: "absolute",
            zIndex: 1,
          }}
        >
          <MyMapSettings
            colorBy={colorBy}
            setColorBy={setColorBy}
            maxAngleDegrees={maxAngleDegrees}
            setMaxAngleDegrees={setMaxAngleDegrees}
            minOverlapPercentage={minOverlapPercentage}
            setMinOverlapPercentage={setMinOverlapPercentage}
            minCongestionCoefficient={minCongestionCoefficient}
            setMinCongestionCoefficient={setMinCongestionCoefficient}
            // congestionRateBinWidthMinutes={congestionRateBinWidthMinutes}
            // setCongestionRateBinWidthMinutes={setCongestionRateBinWidthMinutes}
            areAlertsVisible={areAlertsVisible}
          />
        </div>
        <MyMap
          geojsonData={geojsonData}
          setSelectedRoadSegmentGeomMd5={setSelectedRoadSegmentGeomMd5}
          setSelectedAlertGeomMd5={setSelectedAlertGeomMd5}
          hoveredFeatureId={hoveredFeatureId}
          selectedFeatureId={selectedFeatureId}
          backend_url={backend_url}
          backend_token={token}
          from={from}
          to={to}
          areAlertsVisible={areAlertsVisible}
          jamsOrIrregularities={areJamsVisible ? "jams" : "irregularities"}
          colorBy={colorBy}
          maxAngleDegrees={maxAngleDegrees}
          minOverlap={minOverlapPercentage / 100.0}
          minCongestionCoefficient={minCongestionCoefficient}
          congestionRateBinWidthMinutes={CONGESTION_RATE_BIN_WIDTH_MINUTES}
        />
      </div>
    </div>
  );
}
