import { memo, useState } from "react";

import locale from "plotly.js-locales/fr";
import Plotly from "react-plotly.js";

const Plot = memo(
  ({
    plotData,
    from,
    to,
    yaxisTitle,
    setHoveredFeatureId,
    setSelectedFeatureId,
  }) => {
    const [annotations, setAnnotations] = useState([]);

    const handleHover = (data) => {
      if (data.points.length === 1) {
        setHoveredFeatureId(data.points[0].customdata);
      }
    };

    const handleUnhover = () => {
      setHoveredFeatureId("");
    };

    const handleClick = (data) => {
      if (data.points.length === 1) {
        const point = data.points[0];
        setSelectedFeatureId(point.customdata);
        // doc: https://plotly.com/javascript/reference/layout/annotations/
        setAnnotations([
          {
            x: point.x,
            y: point.y,
            xref: "x",
            yref: "y",
            showarrow: true,
            arrowsize: 0.5,
            arrowhead: 2,
            arrowcolor: "gray",
            ax: 0,
            ay: -40,
            bgcolor: "rgba(255, 255, 255, 0.9)",
            font: { size: 12 },
            bordercolor: point.fullData.marker.color,
            borderwidth: 3,
            borderpad: 4,
            text: "Sélection",
            hovertext: "Cliquez ici pour annuler la sélection",
          },
        ]);
      }
    };

    const handleClickAnnotation = () => {
      setSelectedFeatureId("");
      setAnnotations([]);
    };

    return (
      <Plotly
        data={plotData}
        useResizeHandler={true}
        style={{ width: "100%", height: 250 }}
        layout={{
          autosize: true,
          uirevision: true,
          margin: {
            l: 60,
            r: 100,
            b: 5,
            t: 20,
            pad: 0,
          },
          xaxis: {
            // title: "t",
            range: [`${from}`, `${to}`],
            tickformat: "%H:%M\n%a %d-%m-%y",
            rangeslider: {
              range: [`${from}`, `${to}`],
            },
            type: "date",
          },
          yaxis: {
            title: yaxisTitle,
            fixedrange: false,
            zeroline: false,
          },
          legend: {
            itemsizing: "trace",
          },
          annotations: annotations,
        }}
        config={{
          locales: { fr: locale },
          locale: "fr",
          responsive: true,
        }}
        onHover={handleHover}
        onUnhover={handleUnhover}
        onClick={handleClick}
        onClickAnnotation={handleClickAnnotation}
      />
    );
  },
);

export default Plot;
