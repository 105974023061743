import { Select, Space } from "antd";
import React, { useState, useEffect } from "react";

export default function PlotSettings({ options, yAxisVar, setYAxisVar }) {
  const YAxisSetting = ({ variable, setVariable }) => {
    const [selectedValue, setSelectedValue] = useState(variable);

    useEffect(() => {
      const timeoutId = setTimeout(() => {
        setVariable(selectedValue); // this goes upstream to the parent component
      }, 100);
      return () => clearTimeout(timeoutId);
    }, [selectedValue, setVariable]);

    return (
      <Select
        style={{ width: 180 }}
        defaultValue={selectedValue}
        onChange={setSelectedValue}
        options={options}
      />
    );
  };

  return (
    <div>
      <Space>
        Variable sur l'axe y{" "}
        <YAxisSetting
          options={options}
          variable={yAxisVar}
          setVariable={setYAxisVar}
        />
      </Space>
    </div>
  );
}
